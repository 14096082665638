import React, { useContext, useEffect, useState, useRef } from "react";
import { ISalesProps } from "./ISalesProps";
import ApplicationContext from "../../context/ApplicationContext";
import { addReceipt, getProducts } from "../../services/Service";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { IProduct } from "../../types/IProduct";
import loadingImage from "../../images/loading.gif";
import { IItem } from "../../types/IItem";
import { Dropdown, Item, Table } from "semantic-ui-react";
import ReactToPrint from "react-to-print";
import { IReceiptItem } from "../../types/IReceiptItem";
import { IReceipt } from "../../types/IReceipt";
import { Border } from "react-bootstrap-icons";
import { Printers } from "../printers";

interface ISearchData {
  key: string;
  value: string;
  text: string;

  productId: number;
  barcode: string;
}

export const Sales: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [loading, setLoading] = React.useState(false);
  const [printView, setPrintView] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState("");
  const [showComplete, setShowComplete] = React.useState(false);
  const [searchData, setSearchData] = React.useState<ISearchData[]>([]);
  const [products, setProducts] = React.useState<IProduct[]>([]);
  const [receiptItems, setReceiptItems] = React.useState<IReceiptItem[]>([]);
  const [itemsTotal, setItemsTotal] = React.useState(0);
  const [showOptions, setShowOptions] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  //form
  const [formName, setFormName] = React.useState("");
  const [formTelephone, setFormTelephone] = React.useState("");
  const [formAddress, setFormAddress] = React.useState("");
  const [formNotes, setFormNotes] = React.useState("");

  const componentRef = React.useRef<HTMLInputElement>(null);

  const user = context.AuthenticatedUser.getUser();

  const defaultRecipt: IReceipt = {
    receiptId: 0,
    branchId: 0,
    userId: 0,
    name: "",
    telephone: "",
    address: "",
    isDeleted: false,
    createdAt: new Date(),
    createdBy: "",
    items: "",
    receiptItems: [],
    isPrinted: false,
    total: 0,
    email: "",
    notes: "",
  };

  const [rData, setRData] = React.useState(defaultRecipt);

  function calculateTotal() {
    let itemsTotal = 0;
    receiptItems.forEach((el) => {
      itemsTotal += el.price;
    });
    setItemsTotal(itemsTotal);
  }

  async function loadProducts() {
    setLoading(true);
    const response = await getProducts(user.branchId);

    if (!response) {
      return;
    }

    if (response.length === 0) {
      setLoading(false);
      return;
    }

    buildData(response);
    setLoading(false);
  }

  function buildData(d: IProduct[]) {
    let dd: IProduct[] = [];

    d.forEach((p) => {
      if (p.isMobile.toString() === "True") {
        p.barcodes.forEach((barcode) => {
          let dProduct: IProduct = { ...p };
          dProduct.barcode = barcode.code;
          if (barcode.isSold === false) {
            dd.push(dProduct);
          }
        });
      } else {
        let codes = "";
        if (p.barcodes.length > 0) {
          p.barcodes.forEach((element) => {
            codes = codes + element.code + ",";
          });
        }
        let dProduct: IProduct = { ...p };
        dProduct.barcode = codes;
        dd.push(dProduct);
      }
    });

    setProducts(dd);
  }

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    createSearchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  function createSearchData() {
    setLoading(true);

    var sd: ISearchData[] = [];
    products.forEach((p) => {
      const uuid = uUID();
      sd.push({
        key: uuid,
        text: p.description + " (" + p.barcode + ")",
        value: uuid,
        productId: p.productId,
        barcode: p.barcode,
      });
    });

    setSearchData(sd);
    setLoading(false);
  }

  useEffect(() => {
    calculateTotal();
  }, [receiptItems]);

  function boolValue(value: boolean) {
    const test = value.toString();

    if (test === "True") {
      return true;
    } else {
      return false;
    }
  }

  async function createReceipt() {
    setLoading(true);

    let items: IItem[] = [];

    receiptItems.forEach((element) => {
      const item: IItem = {
        receiptItemId: element.receiptItemId,
        receiptId: 0,
        branchId: user.branchId,
        createdAt: new Date(),
        productId: element.productId,
        description: element.description,
        price: element.price,
        qty: element.qty,
        barcode: element.barcode,
        total: element.total,
        isMobile: element.isMobile,
        isReturned: false,
        returnNotes: "",
      };
      items.push(item);
    });

    const receipt: IReceipt = {
      receiptId: 0,
      branchId: user.branchId,
      userId: user.userId,
      isDeleted: false,
      createdAt: new Date(),
      createdBy: user.displayName,
      items: JSON.stringify(items),
      name: formName,
      telephone: formTelephone,
      address: formAddress,
      receiptItems: items,
      isPrinted: false,
      total: itemsTotal,
      email: "",
      notes: formNotes,
    };

    const receiptId = await addReceipt(receipt);

    if (!receiptId) {
      return;
    }

    receipt.receiptId = receiptId;

    context.Data.setSelectedReceipt(receipt);

    setRData(receipt);

    setLoading(false);
  }

  function completeAction() {
    createReceipt();

    //if mobile items remove from the options

    for (let idx = 0; idx < receiptItems.length; idx++) {
      //if(receiptItems[idx].pr)
      //const element = array[idx];
    }

    setPrintView(true);
    setReceiptItems([]);
    setSelectedId("");
  }

  function uUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function onProductSelected(event: any, data: any) {
    if (data.value === undefined) {
      return;
    }

    let productId: number = 0;
    let barcode: string = "";

    data.options.forEach(
      (o: { key: string; productId: number; barcode: string }) => {
        if (o.key === data.value) {
          productId = o.productId;
          barcode = o.barcode;
        }
      }
    );

    var found = findProductByProductId(productId);

    if (found === undefined) {
      return;
    }

    if (found.barcode === "999999") {
      return;
    }

    var item: IReceiptItem = {
      id: uUID(),
      receiptItemId: found.productId,
      productId: found.productId,
      description: found.description,
      price: found.salePrice,
      barcode: barcode,
      qty: 1,
      total: found.salePrice,
      isMobile: found.isMobile.toString() === "True" ? true : false,
    };

    let items = [...receiptItems];
    items.push(item);
    setReceiptItems(items);
  }

  function onRowClicked(id: string) {
    setSelectedId(id);
  }

  function findProductByProductId(id: number) {
    return products.find((element) => {
      return element.productId === id;
    });
  }

  function findProductByBarcode(id: string) {
    return products.find((element) => {
      return element.barcode === id;
    });
  }

  function handleOnSearchChange(event: any, value: any) {
    setSearchValue(value.searchQuery);
    if (value.searchQuery === "") {
      return;
    }
    if (value.searchQuery === undefined) {
      return;
    }
    if (value.searchQuery < 14) {
      return;
    }

    var found = findProductByBarcode(value.searchQuery);

    if (found === undefined) {
      return;
    }

    var item: IReceiptItem = {
      id: uUID(),
      receiptItemId: found.productId,
      productId: found.productId,
      description: found.description,
      price: found.salePrice,
      barcode: found.barcode,
      qty: 1,
      total: found.salePrice,
      isMobile: found.isDeleted,
    };

    let items = [...receiptItems];
    items.push(item);
    setReceiptItems(items);

    setSearchValue("");
  }

  function handleComplete() {
    if (receiptItems.length === 0) {
      return;
    }
    setShowComplete(true);
  }

  const searchProduct = () => (
    <>
      <Dropdown
        button
        //className="icon"
        fluid
        //labeled
        //icon="world"
        options={searchData}
        search
        clearable={true}
        value={searchValue}
        defaultSearchQuery={searchValue}
        searchQuery={searchValue}
        text="search product"
        onSearchChange={handleOnSearchChange}
        onChange={onProductSelected}
        basic={true}
        //onClick={onProductSelected}
        item={true}
        selectOnNavigation={true}
        selectOnBlur={false}
      />
      <br />
    </>
  );

  const modalComplete = (): JSX.Element => {
    return (
      <Modal show={showComplete} onHide={() => setShowComplete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Total: {itemsTotal.toFixed(2)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Name </InputGroup.Text>
              <Form.Control
                placeholder="name"
                aria-label="name"
                aria-describedby="basic-addon1"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Telephone</InputGroup.Text>
              <Form.Control
                placeholder="telephone"
                aria-label="telephone"
                aria-describedby="basic-addon1"
                onChange={(e) => setFormTelephone(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Address</InputGroup.Text>
              <Form.Control
                size="lg"
                as="textarea"
                aria-label="With textarea"
                onChange={(e) => setFormAddress(e.target.value)}
              />
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroup.Text>Notes</InputGroup.Text>
              <Form.Control
                size="lg"
                as="textarea"
                aria-label="With textarea"
                onChange={(e) => setFormNotes(e.target.value)}
              />
            </InputGroup>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowComplete(false)}>
            Close
          </Button>
          <Button variant="success" onClick={completeAction}>
            Save and Print
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function closeOptions() {
    setShowOptions(false);
  }

  function voidReceipt() {
    setReceiptItems([]);
    setSelectedId("");
    closeOptions();
  }
  function deleteSelectedItem() {
    if (selectedId === undefined) {
      return;
    }
    if (selectedId === "") {
      return;
    }

    let idx = -1;

    for (let index = 0; index < receiptItems.length; index++) {
      if (receiptItems[index].id === selectedId) {
        idx = index;
      }
    }

    let items = [...receiptItems];
    items.splice(idx, 1);
    setReceiptItems(items);

    setSelectedId("");
    closeOptions();
  }

  const additionalOptions = (): JSX.Element => {
    return (
      <>
        <Modal show={showOptions} onHide={closeOptions}>
          <Modal.Header closeButton>
            <Modal.Title>Options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    type="button"
                    onClick={voidReceipt}
                  >
                    Void
                  </button>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 10 }}>
                <div className="col">
                  <button
                    className="btn btn-danger"
                    style={{ width: "100%" }}
                    type="button"
                    onClick={deleteSelectedItem}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeOptions}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function isSelected(id: string) {
    if (selectedId === id) {
      return true;
    }
    return false;
  }

  const table = () => (
    <div>
      <button type="button" className="btn btn-outline-primary btn-lg">
        Main
      </button>{" "}
      <button type="button" className="btn btn-outline-primary btn-lg">
        Q+
      </button>{" "}
      <button type="button" className="btn btn-outline-primary btn-lg">
        Q-
      </button>{" "}
      <div>
        <br />
      </div>
      <div style={{ overflowY: "scroll", maxHeight: "50vh" }}>
        <Table unstackable selectable color={"blue"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Qty</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {receiptItems.map((data) => (
              <Table.Row
                active={isSelected(data.id)}
                positive={isSelected(data.id)}
                onClick={() => {
                  onRowClicked(data.id);
                }}
              >
                <Table.Cell>
                  {data.description} IMEI/Barcode {data.barcode}{" "}
                </Table.Cell>
                <Table.Cell>{data.price.toFixed(2)}</Table.Cell>
                <Table.Cell>{data.qty}</Table.Cell>
                <Table.Cell>£{data.price.toFixed(2)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="row" style={{ paddingTop: 10 }}>
        <div className="col"></div>
        <div className="col-md-auto"></div>
        <div className="col col-lg-2">
          <Form.Control
            type="text"
            value={"£" + itemsTotal.toFixed(2)}
            placeholder="0"
          />
        </div>
      </div>
      <div className="row" style={{ paddingTop: 10 }}>
        <div className="col">
          <button
            type="button"
            className="btn btn-outline-primary btn-lg"
            onClick={() => setShowOptions(true)}
          >
            Options
          </button>{" "}
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <Button variant="success btn-lg" onClick={handleComplete}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );

  function addItem(id: number) {
    var found = findProductByProductId(id);

    if (found === undefined) {
      return;
    }

    if (found.barcode === "999999") {
      return;
    }

    var item: IReceiptItem = {
      id: uUID(),
      receiptItemId: found.productId,
      productId: found.productId,
      description: found.description,
      price: found.salePrice,
      barcode: "barcode",
      qty: 1,
      total: found.salePrice,
      isMobile: found.isMobile.toString() === "True" ? true : false,
    };

    let items = [...receiptItems];
    items.push(item);
    setReceiptItems(items);
  }

  const productItems = () => (
    <div>
      <div>
        <button type="button" className="btn btn-outline-primary btn-lg">
          Category 1
        </button>{" "}
        <button type="button" className="btn btn-outline-primary btn-lg">
        Category 2
        </button>{" "}
        <button type="button" className="btn btn-outline-primary btn-lg">
        Category 3
        </button>{" "}
        <div>
          <br />
        </div>
        {products.map((data) => (
          <>
            <Button
              variant="outline-primary bt-lg"
              style={{ width: 100, height: 100 }}
              onClick={() => addItem(data.productId)}
            >
              {data.description}
            </Button>{" "}
          </>
        ))}
      </div>
    </div>
  );

  const receipt = (): JSX.Element => {
    return (
      <>
        <h3>Sales</h3>
        <hr />
        <div>
          <div className="" style={{ padding: "10px" }}>
            <div className="row">
              <div style={{ width: "59%" }}>
                <div>{searchProduct()}</div>
                <div>{productItems()}</div>
              </div>
              <div style={{ padding: "3px" }}></div>
              <div style={{ width: "39%" }}>
                <div>{table()}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <div>{searchProduct()}</div>
          <div>{table()}</div> */}

          {/* <div className="row" style={{ paddingTop: 10 }}>
            <div className="col"></div>
            <div className="col-md-auto"></div>
            <div className="col col-lg-2">
              <Form.Control
                type="text"
                value={"£" + itemsTotal.toFixed(2)}
                placeholder="0"
              />
            </div>
          </div>
          <div className="row" style={{ paddingTop: 10 }}>
            <div className="col">
              <Button
                variant="primary btn-lg"
                onClick={() => setShowOptions(true)}
              >
                Options
              </Button>
            </div>
            <div className="col" style={{ textAlign: "right" }}>
              <Button variant="success btn-lg" onClick={handleComplete}>
                Next
              </Button>
            </div>
          </div> */}
          <br />
        </div>
      </>
    );
  };
  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  function endOfPrint() {
    setPrintView(false);
    setShowComplete(false);
  }

  const receiptPrinter = (): JSX.Element => {
    return (
      <>
        <div style={{ paddingTop: 20 }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary btn-lg">Print Receipt</Button>
            )}
            content={() => componentRef.current}
            //onBeforeGetContent={() => completeAction()}
            //onAfterPrint={() => setPrintView(false)}
          />
          {"  "}
          <Button variant="secondary btn-lg" onClick={endOfPrint}>
            Close
          </Button>
        </div>
        <div ref={componentRef}>
          <>
            <div
              //className="hidePrinterDivView"
              style={{
                width: "900px",
                margin: "auto",
                paddingTop: "75px",
              }}
            >
              <div className="center-info">
                <div style={{ fontSize: "50px" }}>
                  {context.Configuration.getSettings().name}
                </div>
              </div>
              <div className="center-info">
                <h5>{context.Configuration.getSettings().address}</h5>
              </div>

              <div style={{ paddingTop: 50 }}> </div>
              <div className="row" style={{ paddingTop: 50 }}>
                <div className="col">
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Customer Info
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <div>{rData.name}</div>
                    <div>{rData.address}</div>
                    <div>{rData.telephone}</div>
                    <div>{rData.notes}</div>
                  </div>
                </div>
                <div className="col" style={{ textAlign: "right" }}>
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Receipt
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <div>
                      <div># {rData.receiptId}</div>
                    </div>
                    <div>{new Date(rData.createdAt).toDateString()}</div>
                    <div>{user.displayName}</div>
                  </div>
                </div>
              </div>

              <br />
              <table
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="table table-striped"
                border={1}
              >
                <thead>
                  <tr>
                    <th>Qty</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {rData.receiptItems.map((item) => (
                    <tr>
                      <td>{item.qty}</td>
                      <td>
                        {item.description}
                        {item.isMobile
                          ? " (imei:" + item.barcode + ")"
                          : item.barcode}
                      </td>
                      <td>{item.price}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                className="col"
                style={{ textAlign: "right", paddingRight: 50 }}
              >
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Total : {rData.total}
                </div>
              </div>

              <div className="center-info" style={{ paddingTop: 60 }}>
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Thank you for custom
                </div>
              </div>
            </div>
          </>
        </div>

        <div style={{ paddingTop: 20 }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary btn-lg">Print Receipt</Button>
            )}
            content={() => componentRef.current}
            //onBeforeGetContent={() => completeAction()}
            //onAfterPrint={() => setPrintView(false)}
          />
          {"  "}
          <Button variant="secondary btn-lg" onClick={endOfPrint}>
            Close
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <>
        {loading ? (
          loadingData()
        ) : (
          <>
            {printView ? (
              receiptPrinter()
            ) : (
              <>
                {receipt()}
                {additionalOptions()}
                {modalComplete()}
              </>
            )}
          </>
        )}
      </>

      {/* <div className="wrapper">
        <div>{loading === false ? receipt() : <></>}</div>
        <div>{loading === false ? additionalOptions() : <></>}</div>
        <div>{loading === false ? modalComplete() : <></>}</div>
        <>{loading ? loadingData() : <></>}</>
      </div>
      {printView ? receiptPrinter()} */}
    </>
  );
};
