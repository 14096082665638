import React, { useContext, useEffect, useState, useRef } from "react";
import ApplicationContext from "../../context/ApplicationContext";
import { IReceipt } from "../../types/IReceipt";
import { IPrinterProps } from "./IPrinterProps";

export const Printers: React.FC<IPrinterProps> = (
  props: IPrinterProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const user = context.AuthenticatedUser.getUser();
  const settings = context.Configuration.getSettings();

  const receipt = props.receipt;

  // const defaultRecipt: IReceipt = {
  //   receiptId: 0,
  //   branchId: 0,
  //   userId: 0,
  //   name: "",
  //   telephone: "",
  //   address: "",
  //   isDeleted: false,
  //   createdAt: new Date(),
  //   createdBy: "",
  //   items: "",
  //   receiptItems: [],
  //   isPrinted: false,
  //   total: 0,
  //   email: "",
  //   notes: "",
  // };

  // const [receipt, setReceipt] = React.useState(defaultRecipt);

  const defaultPrinter =
    settings.a4Print === false && settings.thermalPrint === false;

  const thermalPrint = (): JSX.Element => {
    return (
      <>
        <div>get thermal to work here</div>
      </>
    );
  };

  // useEffect(() => {
  //   const d = context.Data.getSelectedReceipt();

  //   if (d === undefined) {
  //     return;
  //   }
  //   setReceipt(d);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [context.Data]);

  const a4Print = (): JSX.Element => {
    return (
      <>
        <div
          //className="hidePrinterDivView"
          style={{
            width: "900px",
            margin: "auto",
            paddingTop: "75px",
          }}
        >
          <div className="center-info">
            <div style={{ fontSize: "50px" }}>
              {context.Configuration.getSettings().name}
            </div>
          </div>
          <div className="center-info">
            <h5>{context.Configuration.getSettings().address}</h5>
          </div>

          <div style={{ paddingTop: 50 }}> </div>
          <div className="row" style={{ paddingTop: 50 }}>
            <div className="col">
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                Customer Info
              </div>
              <div style={{ textAlign: "left" }}>
                <div>{receipt.name}</div>
                <div>{receipt.address}</div>
                <div>{receipt.telephone}</div>
                <div>{receipt.notes}</div>
              </div>
            </div>
            <div className="col" style={{ textAlign: "right" }}>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                Receipt
              </div>
              <div style={{ textAlign: "right" }}>
                <div>
                  <div># {receipt.receiptId}</div>
                </div>
                <div>{new Date(receipt.createdAt).toDateString()}</div>
                <div>{user.displayName}</div>
              </div>
            </div>
          </div>

          <br />
          <table
            style={{
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="table table-striped"
            border={1}
          >
            <thead>
              <tr>
                <th>Qty</th>
                <th>Description</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {receipt.receiptItems.map((item) => (
                <tr>
                  <td>{item.qty}</td>
                  <td>
                    {item.description}
                    {item.isMobile
                      ? " (imei:" + item.barcode + ")"
                      : item.barcode}
                  </td>
                  <td>{item.price}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="col" style={{ textAlign: "right", paddingRight: 50 }}>
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              Total : {receipt.total}
            </div>
          </div>

          <div className="center-info" style={{ paddingTop: 60 }}>
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              Thank you for custom
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {settings.a4Print ? a4Print() : <></>}
      {settings.thermalPrint ? thermalPrint() : <></>}
      {defaultPrinter ? a4Print() : <></>}
    </>
  );
};
