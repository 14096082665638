import React, { useContext, useEffect, useState } from "react";
import { IReportsProps } from "./IReportsProps";
import ApplicationContext from "../../context/ApplicationContext";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { IProduct } from "../../types/IProduct";
import { IItem } from "../../types/IItem";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart, registerables } from "chart.js";
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { dateRangeReceipts, getProducts } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import { IReceipt } from "../../types/IReceipt";
import { ISearch } from "../../types/ISearch";

interface IReportItem {
  productId: number;
  productName: string;
  SoldCount: number;
}

export const Reports: React.FC<IReportsProps> = (
  props: IReportsProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState<IReportItem[]>([]);
  const [startDate, setStartDate] = React.useState(Date);
  const [endDate, setEndDate] = React.useState(Date);
  const [productData, setProductData] = React.useState<IProduct[]>([]);
  const [receiptData, setReceiptData] = React.useState<IReceipt[]>([]);
  const [totalSale, setTotalSale] = React.useState(0);
  const [totalReceipts, setTotalReceipts] = React.useState(0);

  const user = context.AuthenticatedUser.getUser();

  Chart.register(...registerables);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = ["January", "Feb"];

  const barData = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [165, 100, 12, 56],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "Dataset 2",
        data: [35, 95],
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "a",
        data: [165, 100.5],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "b",
        data: [35, 95],
        backgroundColor: "rgb(75, 192, 192)",
      },
    ],
  };

  async function loadProducts() {
    setLoading(true);
    const response = await getProducts(user.branchId);

    if (!response) {
      return;
    }

    if (response.length === 0) {
      return;
    }

    setProductData(response);
    setLoading(false);
  }

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadReceipts() {
    setLoading(true);

    let sDate = new Date(startDate);
    let eDate = new Date(endDate);
    eDate.setHours(23);
    eDate.setMinutes(59);

    const search: ISearch = {
      branchId: user.branchId,
      start: sDate,
      end: eDate,
    };

    const response = await dateRangeReceipts(search);

    if (!response) {
      return;
    }

    let total = 0;

    response.forEach((r) => {
      total = total + r.total;
    });

    setTotalReceipts(response.length);
    setTotalSale(total);
    setReceiptData(response);

    updateFigures(response);

    setLoading(false);
  }

  function find(reports: IReportItem[], productId: number) {
    return reports.find((element) => {
      return element.productId === productId;
    });
  }

  function findIdx(reports: IReportItem[], productId: number) {
    let idx = -1;

    for (let index = 0; index < reports.length; index++) {
      if (reports[index].productId === productId) {
        idx = index;
      }
    }

    return idx;
  }

  function findProductByProductId(id: number) {
    return productData.find((element) => {
      return element.productId === id;
    });
  }

  function getStartDateInput() {
    const dateObj = new Date(startDate);

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    return shortDate;
  }

  function getEndDateInput() {
    const dateObj = new Date(endDate);

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    return shortDate;
  }

  function updateFigures(receipts: IReceipt[]) {
    let reports: IReportItem[] = [];

    receipts.forEach((r) => {
      r.receiptItems.forEach((ri) => {
        const idx = findIdx(reports, ri.productId);

        if (idx === -1) {
          const product = findProductByProductId(ri.productId);

          const report: IReportItem = {
            productId: ri.productId,
            productName: product ? product.description : "",
            SoldCount: ri.qty,
          };

          reports.push(report);
        } else {
          //update the report

          let rItem: IReportItem = JSON.parse(JSON.stringify(reports[idx]));
          rItem.SoldCount = rItem.SoldCount + ri.qty;
          reports[idx] = rItem;
        }
      });
    });

    setData(reports.sort((q) => q.SoldCount).reverse());
  }

  function calculate() {
    let total = 0;

    receiptData.forEach((r) => {
      total = total + r.total;
    });

    setTotalReceipts(receiptData.length);

    setTotalSale(total);
  }

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptData]);

  function searchData() {
    loadReceipts();
  }

  function reports() {
    return (
      <>
        <div>
          <h2>Reports</h2>
          <hr />
          <div>
            <div>
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="2">
                    From
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="Date"
                      value={getStartDateInput()}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    To
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="Date"
                      value={getEndDateInput()}
                      defaultValue={endDate}
                      defaultChecked={true}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    <Button variant="primary" onClick={searchData}>
                      Submit
                    </Button>
                  </Form.Label>
                </Form.Group>
              </Form>
              <br />
              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Total Sale
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={totalSale.toFixed(2)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Total items sold
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={totalReceipts}
                    />
                  </Col>
                </Form.Group>
              </Form>
              <br />
              <h4>Items</h4>
              <br />
              <Table
                striped
                bordered
                hover
                style={{ width: "100%" }}
                className="table-responsive-xl"
              >
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Total Sold</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d) => (
                    <tr>
                      <td>{d.productName}</td>
                      <td>{d.SoldCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <br />
            </div>
            {/* <Bar options={options} data={barData} /> */}
          </div>
        </div>
      </>
    );
  }
  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  return (
    <>
      <>{loading ? loadingData() : reports()}</>
    </>
  );
};
