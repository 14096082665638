import React, { useContext, useEffect, useRef, useState } from "react";
import { IProductProps } from "./IProductProps";
import {
  Button,
  Dropdown,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { IProduct, IProductNew } from "../../types/IProduct";
import {
  addProduct,
  getProducts,
  updateProduct,
  deleteProduct as removeProduct,
} from "../../services/Service";
import { IBarcode } from "../../types/IBarcode";
import loadingImage from "../../images/loading.gif";
import {
  ArrowRight,
  BarChartLineFill,
  FileCodeFill,
  FilterCircleFill,
  Link,
} from "react-bootstrap-icons";
import ApplicationContext from "../../context/ApplicationContext";

interface IFormValid {
  valid: boolean | undefined;
}

export const Product: React.FC<IProductProps> = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [newView, setNewView] = React.useState(false);
  const [showImeiModal, setShowImeiModal] = React.useState(false);
  const [productData, setProductData] = React.useState<IProduct[]>([]);
  const [loading, setLoading] = React.useState(false);

  //form
  const [barcodes, setBarcodes] = React.useState<string[]>([]);
  const [formBarcode, setFormBarcode] = React.useState("");
  const [formDescription, setFormDescription] = React.useState("");
  const [formQty, setFormQty] = React.useState(0);
  const [formSalePrice, setFormSalePrice] = React.useState(0);
  const [formCostPrice, setFormCostPrice] = React.useState(0);
  const [formNotes, setFormNotes] = React.useState("");
  const [formGrade, setFormGrade] = React.useState("Grade A");
  const [formIsMobile, setFormIsMobile] = React.useState(false);
  const [formQtyDisabled, setFormQtyDisabled] = React.useState(true);

  const [editMode, setEditMode] = React.useState(false);
  const [productId, setProductId] = React.useState(0);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const defaultValid: IFormValid = {
    valid: undefined,
  };

  const [formBarcodeValid, setFormBarcodeValid] = React.useState(defaultValid);

  const user = context.AuthenticatedUser.getUser();

  async function loadProducts() {
    const response = await getProducts(user.branchId);

    if (!response) {
      return;
    }

    if (response.length === 0) {
      return;
    }

    setProductData(response);
    setLoading(false);
  }

  useEffect(() => {
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onEnterHit(e: any) {
    if (e.key === "Enter") {
      addIMEIToFormField();
    }
  }
  function addIMEI() {
    addIMEIToFormField();
  }

  function addIMEIToFormField() {
    var bcodes = [...barcodes];
    bcodes.push(formBarcode);

    setBarcodes(bcodes);
    setFormBarcode("");
  }

  function removeBarcode(idx: number) {
    let codes = [...barcodes];
    codes.splice(idx, 1);
    setBarcodes(codes);
  }

  function getBarcodes(ids: IBarcode[]) {
    let d = "";
    ids.forEach((element) => {
      d += element + ",";
    });

    return d;
  }

  function getFormBarcodes() {
    let d = "";
    barcodes.forEach((element) => {
      d += element + ",";
    });

    return d;
  }

  function boolValue(value: boolean) {
    const test = value.toString();

    if (test === "True") {
      return true;
    } else {
      return false;
    }
  }

  function showNewProductView() {
    setFormQtyDisabled(false);
    setFormIsMobile(false);
    setNewView(true);
  }

  function confirmDelete() {
    removeProduct(productId);

    setTimeout(function () {
      loadProducts();
    }, 1000);

    setShowDeleteModal(false);
  }

  function addNewProduct() {
    //some validation here!
    const defaultValid: IFormValid = {
      valid: true,
    };

    setFormBarcodeValid(defaultValid);

    if (formIsMobile) {
      if (barcodes.length === 0) {
        const valid: IFormValid = {
          valid: false,
        };
        setFormBarcodeValid(valid);
        return;
      }
    }

    let newProduct: IProductNew = {
      branchId: 1,
      description: formDescription,
      quantity: formQty,
      costPrice: formCostPrice,
      salePrice: formSalePrice,
      notes: formNotes,
      grade: formGrade,
      barcodes: barcodes,
      createdBy: "epos - user",
      isMobile: formIsMobile,
      editedProductId: productId,
    };

    if (editMode === true) {
      updateProduct(newProduct);
    } else {
      addProduct(newProduct);
    }

    setNewView(false);
    setEditMode(false);

    setTimeout(function () {
      loadProducts();
    }, 1000);
  }

  const imeiModal = (): JSX.Element => {
    return (
      <Modal show={showImeiModal} onHide={() => setShowImeiModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Barcode/IMEI's</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="input-group">
              <input
                type="text"
                className="form-control rounded"
                placeholder="Add barcodes here"
                value={formBarcode}
                onChange={(e) => setFormBarcode(e.target.value)}
                onKeyUp={onEnterHit}
                autoFocus
                onFocus={(e) => e.currentTarget.select()}
                ref={(inputElement) => {
                  // constructs a new function on each render
                  if (inputElement) {
                    inputElement.focus();
                  }
                }}
              />
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={addIMEI}
              >
                Add
              </button>
            </div>
            <br />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Barcode</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {barcodes.map(function (d, idx) {
                  return (
                    <>
                      <tr>
                        <td>{idx}</td>
                        <td>{d}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            value={d}
                            onClick={() => removeBarcode(idx)}
                          >
                            x
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setShowImeiModal(false)}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function isMobileSale() {
    if (formIsMobile) {
      setFormIsMobile(false);
      setFormQtyDisabled(false);
    } else {
      setFormIsMobile(true);
      setFormQtyDisabled(true);
    }
  }

  const newProductView = (): JSX.Element => {
    return (
      <>
        <h3>New Product</h3>
        <hr />
        <Form>
          <Form.Group className="mb-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={formIsMobile}
                onChange={isMobileSale}
              ></input>
              <label className="form-check-label">I am adding mobile(s)</label>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Description"
              value={formDescription}
              onChange={(e) => setFormDescription(e.target.value)}
              required
              // isInvalid={!descriptionValid}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Qty</Form.Label>
            <Form.Control
              type="number"
              placeholder="Quantity"
              required={true}
              value={formQty}
              onChange={(e) => setFormQty(parseFloat(e.target.value))}
              //  isInvalid={!qtyValid}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Cost Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Cost price"
              value={formCostPrice}
              onChange={(e) => setFormCostPrice(parseFloat(e.target.value))}
              // isInvalid={!costPriceValid}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Sale Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Sale price"
              value={formSalePrice}
              onChange={(e) => setFormSalePrice(parseFloat(e.target.value))}
              // isInvalid={!salePriceValid}
            />
          </Form.Group>
          <FloatingLabel controlId="floatingTextarea2" label="">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Notes"
              value={formNotes}
              style={{ height: "100px" }}
              onChange={(e) => setFormNotes(e.target.value)}
            />
          </FloatingLabel>
          <Form.Group className="mb-3">
            <Form.Label>Grade</Form.Label>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {formGrade}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setFormGrade("Grade A")}>
                  Grade A
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFormGrade("Grade B")}>
                  Grade B
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFormGrade("Grade C")}>
                  Grade C
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFormGrade("Grade D")}>
                  Grade D
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>

          <div>
            <Form.Label>Barcode(s)</Form.Label>
          </div>

          <Form.Label>
            <Button variant="primary" onClick={() => setShowImeiModal(true)}>
              add/edit
            </Button>
          </Form.Label>

          <FloatingLabel controlId="floatingTextarea2" label="">
            <Form.Control
              as="textarea"
              placeholder="Barcode"
              disabled={true}
              value={getFormBarcodes()}
              style={{ height: "100px", paddingTop: 10 }}
              isInvalid={formBarcodeValid.valid === false}
            />
          </FloatingLabel>
        </Form>

        <div style={{ paddingTop: 15 }}>
          <Button
            variant="primary"
            style={{ padding: 10 }}
            onClick={closeNewView}
          >
            Cancel
          </Button>{" "}
          <Button
            variant="success"
            style={{ padding: 10 }}
            onClick={addNewProduct}
          >
            Done
          </Button>
        </div>
      </>
    );
  };

  function closeNewView() {
    setEditMode(false);
    setNewView(false);
  }

  function findProductByProductId(id: number) {
    return productData.find((element) => {
      return element.productId === id;
    });
  }

  function editProduct(id: number) {
    setEditMode(true);
    setProductId(id);

    const product = findProductByProductId(id);

    if (product === undefined) {
      return;
    }

    setFormIsMobile(boolValue(product.isMobile));
    setFormDescription(product.description);
    setFormQty(product.quantity);
    setFormCostPrice(product.costPrice);
    setFormSalePrice(product.salePrice);
    setFormNotes(product.notes);
    setFormGrade(product.grade);
    setFormBarcode(product.barcode);

    const codes: string[] = [];
    product.barcodes.forEach((element) => {
      if (element.isSold === false) {
        codes.push(element.code);
      }
    });

    setBarcodes(codes);
    setNewView(true);
  }

  function deleteProduct(id: number) {
    setProductId(id);

    const product = findProductByProductId(id);

    if (product === undefined) {
      return;
    }

    setShowDeleteModal(true);
  }

  const products = (): JSX.Element => {
    return (
      <div>
        <Table
          striped
          bordered
          hover
          style={{ width: "100%" }}
          className="table-responsive-xl"
        >
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Cost Price</th>
              <th>Sale Price</th>
              <th>Notes</th>
              <th>Grade</th>
              <th>Barcode/IMEI</th>
              <th>Mobile</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productData.map((d) => (
              <tr>
                <td>{d.description}</td>
                <td>{d.quantity}</td>
                <td>{d.costPrice}</td>
                <td>{d.salePrice}</td>
                <td>{d.notes}</td>
                <td>{d.grade}</td>
                <td>{d.barcodes.map((b) => b.code + ", ")}</td>
                <td>{d.isMobile.toString() === "True" ? "Yes" : "No"}</td>
                <td>
                  <span
                    className="glyphicon glyphicon-align-left"
                    aria-hidden="true"
                  ></span>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => editProduct(d.productId)}
                  >
                    Update
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => deleteProduct(d.productId)}
                    className="btn btn-outline-primary"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const deletModal = (): JSX.Element => {
    return (
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h5>Delete this product. Sure ?</h5>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => confirmDelete()}>
            Yes Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const productsView = (): JSX.Element => {
    return (
      <>
        <h3>Products</h3>
        <hr />
        <div style={{ padding: 10 }}>
          <Button variant="success btn-lg" onClick={showNewProductView}>
            New
          </Button>
        </div>
        <div>
          <div>{products()}</div>
        </div>
      </>
    );
  };

  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        loadingData()
      ) : (
        <>
          {newView === false ? productsView() : <></>}
          {newView ? newProductView() : <></>}
          {imeiModal()}
          {deletModal()}
        </>
      )}
    </div>
  );
};
