import { IItem } from "../../types/IItem";
import { IReceiptData } from "./IReceipt";

let items: IItem[] = [];

function findProduct(array: IItem[], id: number) {
  return array.find((element) => {
    return element.productId === id;
  });
}

export class Receipt implements IReceiptData {
  public addItem(item: IItem) {
    items.push(item);
  }
  public getItems(): IItem[] {
    return items;
  }
  public removeItem(id: number) {
    var item = findProduct(items, id);

    if (item === undefined) {
      return;
    }

    const idx = items.indexOf(item);
    items.splice(idx, 1);
  }
  public clear(): void {
    items = [];
  }
}
