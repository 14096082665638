import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Login } from "../login";
import ApplicationContext from "../../context/ApplicationContext";
import { ViewPage } from "../../types/ViewPage";
import { IManagerProps } from "./IManagerProps";
import { Sales } from "../sales";
import { Button, NavDropdown } from "react-bootstrap";
import { Product } from "../product";
import { Settings } from "../settings";
import { Orders } from "../orders";
import { Returns } from "../returns";
import { Transactions } from "../transactions";
import { IUser } from "../../types/IUser";
import { Reports } from "../reports";
import { apiGetSettings } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import { ISettings } from "../../types/ISettings";

export const Manager: React.FC<IManagerProps> = (
  props: IManagerProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [loginView, setLoginView] = useState(true);

  const [regView, setRegView] = useState(false);
  const [salesView, setSalesView] = useState(true);
  const [productView, setProductView] = useState(false);
  const [settingsView, setSettingsView] = useState(false);
  const [ordersView, setOrdersView] = useState(false);
  const [returnsView, setReturnsView] = useState(false);
  const [reportsView, setReportsView] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const settings = context.Configuration.getSettings();

  const user: IUser = {
    success: false,
    information: "",
    sessionGuid: "",
    awaitingApproval: false,
    familyGuid: "",
    branchId: 1,
    displayName: "wa-user",
    userId: 1,
  };

  context.AuthenticatedUser.setUser(user);

  const handleLoginSuccess = () => {
    clearViews();
    setSalesView(true);
    context.ViewPage.setView(ViewPage.home);
  };

  const handleRegister = () => {
    clearViews();
    setRegView(true);
  };
  const handleForgotPassword = () => {
    clearViews();
  };

  function clearViews() {
    setLoginView(false);
    setRegView(false);
    setSalesView(false);
    setSettingsView(false);
    setProductView(false);
    setOrdersView(false);
    setReturnsView(false);
    setReportsView(false);
  }

  function showSalesView() {
    clearViews();
    setSalesView(true);
  }
  function showProductView() {
    clearViews();
    setProductView(true);
  }
  function showReturnsView() {
    clearViews();
    setReturnsView(true);
  }
  function showReportsView() {
    clearViews();
    setReportsView(true);
  }
  function logout() {
    clearViews();
    setLoginView(true);
  }
  function showOrdersView() {
    clearViews();
    setOrdersView(true);
  }
  function showSettingView() {
    clearViews();
    setSettingsView(true);
  }

  async function loadSettings() {
    setLoading(true);

    if (user === undefined) {
      return;
    }

    const response = await apiGetSettings(user.branchId);

    if (!response) {
      return;
    }

    context.Configuration.setSettings(response);

    setLoading(false);
  }

  useEffect(() => {
    loadSettings();
    //const view = context.ViewPage.getView();

    // if (view !== ViewPage.orders) {
    //   clearViews();
    //   setOrdersView(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const template = (): JSX.Element => (
    <>
      <body>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
          <div className="container">
            <Button
              variant="link"
              className="navbar-brand"
              onClick={showSalesView}
            >
              ES
            </Button>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showSalesView}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Sales
                  </Button>
                </li>
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showOrdersView}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Transactions
                  </Button>
                </li>
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showProductView}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Product
                  </Button>
                </li>
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showReturnsView}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Returns
                  </Button>
                </li>
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showReportsView}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Reports
                  </Button>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Account
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="dropdown-item" onClick={showSettingView}>
                      Settings
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" onClick={logout}>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <header className="py-4 mb-1"></header>

        <div className="" style={{ padding: "10px" }}>
          {salesView ? <Sales /> : <></>}
          {productView ? <Product /> : <></>}
          {ordersView ? <Transactions /> : <></>}
          {returnsView ? <Returns /> : <></>}
          {reportsView ? <Reports /> : <></>}
          {settingsView ? <Settings /> : <></>}
        </div>

        {/* <footer className="py-5 bg-dark">
          <div className="container">
            <p className="m-0 text-center text-white">
              Copyright &copy; info@enigmasoftware.co.uk
            </p>
          </div>
        </footer> */}
      </body>
    </>
  );

  const manager = (): JSX.Element => (
    <>
      <>
        {loginView ? (
          <Login
            successful={handleLoginSuccess}
            register={handleRegister}
            forgotPassword={handleForgotPassword}
          />
        ) : (
          <></>
        )}
      </>
      <>{loginView === false ? template() : <></>}</>
    </>
  );

  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  return <div>{loading ? loadingData() : <>{manager()}</>}</div>;
};
