import axios, { AxiosResponse } from "axios";
import { IProduct, IProductNew } from "../types/IProduct";
import { IItem, IReturnItem } from "../types/IItem";
import { IReceipt } from "../types/IReceipt";
import { ISearch } from "../types/ISearch";
import { ISettings } from "../types/ISettings";

export const API = axios.create({
  baseURL: "https://eposmobileapi.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: () => true,
});

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}

export const addProduct = (
  product: IProductNew
): Promise<IProduct[] | void> => {
  return API.post("Products/Add", product)
    .then((response: AxiosResponse<IProduct[]>) => {
      return response.data;
    })
    .catch(handleError);
};

export const updateProduct = (
  product: IProductNew
): Promise<boolean | void> => {
  return API.post("Products/Update", product)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

export const deleteProduct = (id: number): Promise<boolean | void> => {
  return API.post("Products/Delete", id)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

export const getProducts = (branchId: number): Promise<IProduct[] | void> => {
  return API.post("Products/Products", branchId)
    .then((response: AxiosResponse<IProduct[]>) => {
      return response.data;
    })
    .catch(handleError);
};

export const addReceipt = (receipt: IReceipt): Promise<number | void> => {
  return API.post("Receipt/Add", receipt)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

export const getReceipts = (): Promise<IReceipt[] | void> => {
  return API.get("Receipt")
    .then((response: AxiosResponse<IReceipt[]>) => {
      return response.data;
    })
    .catch(handleError);
};

export const returnReceiptItem = (
  receiptItem: IReturnItem
): Promise<boolean | void> => {
  return API.post("Receipt/ReturnItem", receiptItem)
    .then((response: AxiosResponse<boolean>) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return false;
      }
    })
    .catch(handleError);
};

export const dateRangeReceipts = (
  search: ISearch
): Promise<IReceipt[] | void> => {
  return API.post("Receipt/DateRangeReceipts", search)
    .then((response: AxiosResponse<IReceipt[]>) => {
      return response.data;
    })
    .catch(handleError);
};

export const apiGetSettings = (branchId: number): Promise<ISettings | void> => {
  return API.post("Settings/Settings", branchId)
    .then((response: AxiosResponse<ISettings>) => {
      return response.data;
    })
    .catch(handleError);
};

export const apiSetSettings = (
  settings: ISettings
): Promise<ISettings | void> => {
  return API.post("Settings/SaveSettings", settings)
    .then((response: AxiosResponse<ISettings>) => {
      return response.data;
    })
    .catch(handleError);
};
