import React, { useContext, useEffect, useState } from "react";
import { ISettingsProps } from "./ISettingsProps";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import ApplicationContext from "../../context/ApplicationContext";
import loadingImage from "../../images/loading.gif";
import { apiGetSettings, apiSetSettings } from "../../services/Service";
import { ISettings } from "../../types/ISettings";

export const Settings: React.FC<ISettingsProps> = (
  props: ISettingsProps
): JSX.Element => {
  const [thermalPrint, setThermalPrint] = React.useState(false);
  const [a4Print, setA4Print] = React.useState(false);
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showSavedModal, setShowSavedModal] = React.useState(false);

  const context = useContext(ApplicationContext);
  const user = context.AuthenticatedUser.getUser();

  function a4PrintChanged() {
    if (a4Print) {
      setA4Print(false);
    } else {
      setA4Print(true);
    }
  }

  function thermalPrintChanged() {
    if (thermalPrint) {
      setThermalPrint(false);
    } else {
      setThermalPrint(true);
    }
  }

  async function loadSettings() {
    const response = await apiGetSettings(user.branchId);

    if (!response) {
      return;
    }

    setThermalPrint(response.thermalPrint);
    setAddress(response.address);
    setName(response.name);
    setA4Print(response.a4Print);

    context.Configuration.setSettings(response);

    buildSettings();
    setLoading(false);
  }

  function buildSettings() {
    //
  }

  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function saveApiSettings() {
    const settings: ISettings = {
      settingsId: 0,
      a4Print: a4Print,
      thermalPrint: thermalPrint,
      name: name,
      address: address,
      branchId: user.branchId,
      CreatedBy: user.displayName,
      CreatedAt: new Date(),
      ParentId: 0,
    };

    const response = await apiSetSettings(settings);

    if (!response) {
      return;
    }

    context.Configuration.setSettings(response);

    buildSettings();
    setLoading(false);
  }

  function saveSettings() {
    saveApiSettings();
    setShowSavedModal(true);
  }

  const savedModal = (): JSX.Element => {
    return (
      <Modal show={showSavedModal} onHide={() => setShowSavedModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Saved!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h5>Settings has been Saved ?</h5>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSavedModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const settings = (): JSX.Element => {
    return (
      <>
        <h3>Settings</h3>
        <hr />
        <div>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                A4 print
              </Form.Label>
              <Col sm="10" style={{ paddingTop: 10 }}>
                <input
                  type="checkbox"
                  role="switch"
                  checked={a4Print}
                  onChange={a4PrintChanged}
                ></input>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Thermal Printer (80mm)
              </Form.Label>
              <Col sm="10" style={{ paddingTop: 10 }}>
                <input
                  //className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={thermalPrint}
                  onChange={thermalPrintChanged}
                ></input>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Company Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  role="switch"
                  placeholder="Company Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                Address
              </Form.Label>
              <Col sm="10">
                <FloatingLabel controlId="floatingTextarea2" label="">
                  <Form.Control
                    as="textarea"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    style={{ height: "100px", paddingTop: 10 }}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                <Button variant="primary" onClick={saveSettings}>
                  Submit
                </Button>
              </Form.Label>
            </Form.Group>
          </Form>
        </div>
      </>
    );
  };

  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        loadingData()
      ) : (
        <>
          {settings()}
          {savedModal()}
        </>
      )}
    </div>
  );
};
