import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { ITransactionsProps } from "./ITransactionsProps";
import { getReceipts, returnReceiptItem } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import { IReceipt } from "../../types/IReceipt";
import { IItem, IReturnItem } from "../../types/IItem";
import { Printers } from "../printers";
import ReactToPrint from "react-to-print";
import ApplicationContext from "../../context/ApplicationContext";

export const Transactions: React.FC<ITransactionsProps> = (
  props: ITransactionsProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const defaultData: IReceipt[] = [];
  const [loading, setLoading] = React.useState(false);
  const [transactionData, setTransactionsData] = React.useState(defaultData);
  const [filterData, setFilterData] = React.useState(defaultData);

  const [itemId, setItemId] = React.useState(0);
  const [receiptId, setReceiptId] = React.useState(0);
  const [showReturnModal, setShowReturnModal] = React.useState(false);
  const [showReceiptModal, setShowReceiptModal] = React.useState(false);

  //form fields
  const [formTitle, setFormTitle] = React.useState("");
  const [formName, setFormName] = React.useState("");
  const [formTelephone, setFormTelephone] = React.useState("");
  const [formEmail, setFormEmail] = React.useState("");
  const [formNotes, setFormNotes] = React.useState("");

  const [printView, setPrintView] = React.useState(false);

  const defaultRecipt: IReceipt = {
    receiptId: 0,
    branchId: 0,
    userId: 0,
    name: "",
    telephone: "",
    address: "",
    isDeleted: false,
    createdAt: new Date(),
    createdBy: "",
    items: "",
    receiptItems: [],
    isPrinted: false,
    total: 0,
    email: "",
    notes: "",
  };

  const [printReceipt, setPrinterReceipt] = React.useState(defaultRecipt);

  const componentRef = React.useRef<HTMLInputElement>(null);

  async function LoadOrders() {
    setLoading(true);

    const response = await getReceipts();

    if (!response) {
      return;
    }

    if (response.length === 0) {
      setLoading(false);
      return;
    }

    response.forEach((r) => {
      const items: IItem[] = JSON.parse(r.items);
      r.receiptItems = items;
    });

    setTransactionsData(response);
    setFilterData(response);

    setLoading(false);
  }

  useEffect(() => {
    LoadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function findReceiptById(id: number) {
    return transactionData.find((element) => {
      return element.receiptId === id;
    });
  }
  function returnItem(item: IItem, receiptId: number) {
    setItemId(item.receiptItemId);
    setReceiptId(item.receiptId);
    //Populate as much of the form as possible

    const receipt = findReceiptById(item.receiptId);

    if (receipt !== undefined) {
      setFormName(receipt.name);
      setFormTelephone(receipt.telephone);
      setFormEmail(receipt.email);
    }

    setFormTitle(item.description + " (" + item.barcode + ")");
    setShowReturnModal(true);
  }

  async function completeReturn() {
    let rItem: IReturnItem = {
      itemId: itemId,
      receiptId: receiptId,
      name: formName,
      telephone: formTelephone,
      address: "",
      notes: formNotes,
      email: formEmail === null || formEmail === undefined ? "" : formEmail,
    };

    const response = await returnReceiptItem(rItem);

    if (!response) {
      return;
    }

    const receipt = findReceiptById(receiptId);

    if (receipt === undefined) {
      return;
    }

    context.Data.setSelectedReceipt(receipt);

    setShowReturnModal(false);
    LoadOrders();
  }

  const modalReceipt = (): JSX.Element => {
    return (
      <Modal show={showReceiptModal} onHide={() => setShowReceiptModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="container">
              <div className="row">
                <div className="col order-0">test</div>
                <div className="auto">Second in DOM, with a larger order</div>
                <div className="col order-1">
                  Third in DOM, with an order of 1
                </div>
              </div>
            </div>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Name </InputGroup.Text>
              <Form.Control
                placeholder="telephone"
                aria-label="telephone"
                aria-describedby="basic-addon1"
                value={formTelephone}
                disabled={true}
                onChange={(e) => setFormTelephone(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Telephone</InputGroup.Text>
              <Form.Control
                placeholder="telephone"
                aria-label="telephone"
                aria-describedby="basic-addon1"
                value={formTelephone}
                onChange={(e) => setFormTelephone(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
              <Form.Control
                placeholder="email"
                aria-label="telephone"
                aria-describedby="basic-addon1"
                value={formEmail}
                onChange={(e) => setFormEmail(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Reason for return</InputGroup.Text>
              <Form.Control
                size="lg"
                as="textarea"
                value={formNotes}
                aria-label="With textarea"
                onChange={(e) => setFormNotes(e.target.value)}
              />
            </InputGroup>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowReceiptModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setShowReceiptModal(false)}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const modalComplete = (): JSX.Element => {
    return (
      <Modal show={showReturnModal} onHide={() => setShowReturnModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{formTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Name </InputGroup.Text>
              <Form.Control
                placeholder=""
                aria-label="name"
                aria-describedby="basic-addon1"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Telephone</InputGroup.Text>
              <Form.Control
                placeholder="telephone"
                aria-label="telephone"
                aria-describedby="basic-addon1"
                value={formTelephone}
                onChange={(e) => setFormTelephone(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="lg" className="mb-3">
              <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
              <Form.Control
                placeholder="email"
                aria-label="telephone"
                aria-describedby="basic-addon1"
                value={formEmail}
                onChange={(e) => setFormEmail(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Reason for return</InputGroup.Text>
              <Form.Control
                size="lg"
                as="textarea"
                value={formNotes}
                aria-label="With textarea"
                onChange={(e) => setFormNotes(e.target.value)}
              />
            </InputGroup>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowReturnModal(false)}>
            Cancel
          </Button>
          <Button variant="btn btn-danger" onClick={completeReturn}>
            Return and Print
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function receipts() {
    return (
      <>
        <br />
        {filterData.map((d) => (
          <>
            <div style={{ padding: 10 }}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {new Date(d.createdAt).toLocaleString()}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    created by : {d.createdBy} <br />
                    Receipt Id : {d.receiptId} <br />
                    User: {d.createdBy} <br />
                    Notes: {d.notes} <br />
                    Name: {d.name} <br />
                    Address: {d.address} <br />
                    Tel: {d.telephone} <br />
                    Email: {d.email} <br />
                    Date: {d.createdAt.toString()} <br />
                  </Card.Subtitle>
                  <Table
                    striped
                    bordered
                    hover
                    style={{ width: "100%" }}
                    className="table-responsive-xl"
                  >
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Barcode/IMEI</th>
                        <th>Mobile</th>
                        <th>Returned</th>
                        <th>Returned Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {d.receiptItems.map((item) => (
                        <tr>
                          <td>{item.description}</td>
                          <td>{item.qty}</td>
                          <td>{item.price}</td>
                          <td>{item.barcode}</td>
                          <td>{item.isMobile.toString()}</td>
                          <td>{item.isReturned ? "Yes" : "No"}</td>
                          <td>{item.returnNotes}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() =>
                                returnItem(item, item.receiptItemId)
                              }
                            >
                              {item.isReturned ? "Update" : "Return"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <button type="button" className="btn btn-outline-primary">
                    Total: {d.total.toFixed(2)}
                  </button>{" "}
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => showPrintReceipt(d)}
                  >
                    Re-Print
                  </button>
                </Card.Body>
              </Card>
            </div>
          </>
        ))}
      </>
    );
  }

  function showPrintReceipt(receipt: IReceipt) {
    setPrinterReceipt(receipt);
    setPrintView(true);
  }

  function setReceiptIdInContext(r: IReceipt) {
    context.Data.setSelectedReceipt(r);

    setTimeout(function () {
      //just wait
    }, 2000);
  }

  function filterByReceiptId(receiptId: string) {
    const receipts: IReceipt[] = [];
    var id = parseInt(receiptId);

    transactionData.forEach((d) => {
      if (d.receiptId === id) {
        receipts.push(d);
      }
    });

    if (receipts.length > 0) {
      setFilterData(receipts);
    } else {
      const allReceipts: IReceipt[] = [...transactionData];
      setFilterData(allReceipts);
    }
  }

  function filterByIMEI(imei: string) {
    const receipts: IReceipt[] = [];

    transactionData.forEach((d) => {
      d.receiptItems.forEach((item) => {
        if (item.barcode === imei) {
          receipts.push(d);
        }
      });
    });

    if (receipts.length > 0) {
      setFilterData(receipts);
    } else {
      const allReceipts: IReceipt[] = [...transactionData];
      setFilterData(allReceipts);
    }
  }

  const transactions = (): JSX.Element => {
    return (
      <>
        <h3>Transactions</h3>
        <hr />
        <>
          <h5>Search By </h5>
          <InputGroup size="lg" className="mb-3">
            <InputGroup.Text id="basic-addon1">Receipt Id</InputGroup.Text>
            <Form.Control
              placeholder="Receipt id"
              aria-describedby="basic-addon1"
              onChange={(e) => filterByReceiptId(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="lg" className="mb-3">
            <InputGroup.Text id="basic-addon1">Barcode</InputGroup.Text>
            <Form.Control
              placeholder="Barcode"
              aria-describedby="basic-addon1"
              onChange={(e) => filterByIMEI(e.target.value)}
            />
          </InputGroup>
        </>
        <div>
          <div>{receipts()}</div>
        </div>
      </>
    );
  };

  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  function endOfPrint() {
    setPrintView(false);
  }

  const receiptPrinter = (): JSX.Element => {
    return (
      <>
        <div style={{ paddingTop: 20 }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary btn-lg">Print Receipt</Button>
            )}
            content={() => componentRef.current}
            //onBeforeGetContent={() => completeAction()}
            //onAfterPrint={() => setPrintView(false)}
          />
          {"  "}
          <Button variant="secondary btn-lg" onClick={endOfPrint}>
            Close
          </Button>
        </div>
        <div ref={componentRef}>
          <Printers receipt={printReceipt} />
        </div>

        <div style={{ paddingTop: 20 }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary btn-lg">Print Receipt</Button>
            )}
            content={() => componentRef.current}
            //onBeforeGetContent={() => completeAction()}
            //onAfterPrint={() => setPrintView(false)}
          />
          {"  "}
          <Button variant="secondary btn-lg" onClick={endOfPrint}>
            Close
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <>
        {loading ? (
          loadingData()
        ) : (
          <>
            {printView ? (
              receiptPrinter()
            ) : (
              <>
                {transactions()}
                {modalComplete()}
                {modalReceipt()}
              </>
            )}
          </>
        )}
      </>
    </>
  );
};
