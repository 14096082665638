import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { IReturns } from "./index";
import { getReceipts, returnReceiptItem } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import { IReceipt } from "../../types/IReceipt";
import { IItem, IReturnItem } from "../../types/IItem";
import ReactToPrint from "react-to-print";
import ApplicationContext from "../../context/ApplicationContext";

interface IReturnInfo {
  receiptId: number;
  description: string;
  barcode: string;
  returnNotes: string;
  productNotes: string;
  returnBy: string;
  returnDate: Date;
  isMobile: boolean;
}

export const Returns: React.FC<IReturns> = (props: IReturns): JSX.Element => {
  const defaultData: IReturnInfo[] = [];
  const defaultReceipts: IReceipt[] = [];

  const [loading, setLoading] = React.useState(false);
  const [transactionData, setTransactionsData] = React.useState(defaultData);
  const [filterData, setFilterData] = React.useState(defaultData);
  const [receiptData, setReceiptData] = React.useState(defaultReceipts);

  const context = useContext(ApplicationContext);

  const defaultReceiptInfo: IReturnInfo = {
    receiptId: 0,
    description: "",
    barcode: "",
    returnNotes: "",
    productNotes: "",
    returnBy: "",
    returnDate: new Date(),
    isMobile: false,
  };

  const [returnInfo, setReturnInfo] = React.useState(defaultReceiptInfo);
  const [printView, setPrintView] = React.useState(false);
  const componentRef = React.useRef<HTMLInputElement>(null);

  async function LoadOrders() {
    setLoading(true);

    const response = await getReceipts();

    if (!response) {
      return;
    }

    if (response.length === 0) {
      setLoading(false);
      return;
    }

    setReceiptData(response);

    response.forEach((r) => {
      const items: IItem[] = JSON.parse(r.items);
      r.receiptItems = items;
    });

    let data: IReturnInfo[] = [];
    response.forEach((r) => {
      r.receiptItems.forEach((ri) => {
        if (ri.isReturned) {
          const info: IReturnInfo = {
            receiptId: r.receiptId,
            description: ri.description,
            barcode: ri.barcode,
            returnNotes: ri.returnNotes,
            productNotes: "",
            returnBy: r.createdBy,
            returnDate: r.createdAt,
            isMobile: ri.isMobile,
          };
          data.push(info);
        }
      });
    });

    setTransactionsData(data);
    setFilterData(data);

    setLoading(false);
  }

  useEffect(() => {
    LoadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function receipts() {
    return (
      <>
        <div style={{ padding: 10 }}>
          <Table
            striped
            bordered
            hover
            style={{ width: "100%" }}
            className="table-responsive-xl"
          >
            <thead>
              <tr>
                <th>Receipt</th>
                <th>Description</th>
                <th>Barcode/IMEI</th>
                <th>Return Notes</th>
                <th>Return By</th>
                <th>Return Date</th>
                <th>Mobile</th>
              </tr>
            </thead>
            <tbody>
              {filterData.map((element) => (
                <tr>
                  <td>{element.receiptId}</td>
                  <td>{element.description}</td>
                  <td>{element.barcode}</td>
                  <td>{element.returnNotes}</td>
                  <td>{element.returnBy}</td>
                  <td>{element.returnDate.toString()}</td>
                  <td>
                    {element.isMobile.toString() === "true" ? "Yes" : "No"}
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => setReturnInfoAndPrint(element)}
                      className="btn btn-outline-primary"
                    >
                      re-print
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  function setReturnInfoAndPrint(returnInfo: IReturnInfo) {
    setReturnInfo(returnInfo);
    setPrintView(true);
  }

  function filterByReceiptId(receiptId: string) {
    const receipts: IReturnInfo[] = [];
    var id = parseInt(receiptId);

    transactionData.forEach((d) => {
      if (d.receiptId === id) {
        receipts.push(d);
      }
    });

    if (receipts.length > 0) {
      setFilterData(receipts);
    } else {
      const allData: IReturnInfo[] = [...transactionData];
      setFilterData(allData);
    }
  }

  function filterByIMEI(imei: string) {
    const receipts: IReturnInfo[] = [];

    transactionData.forEach((d) => {
      if (d.barcode === imei) {
        receipts.push(d);
      }
    });

    if (receipts.length > 0) {
      setFilterData(receipts);
    } else {
      const allReceipts: IReturnInfo[] = [...transactionData];
      setFilterData(allReceipts);
    }
  }

  const returns = (): JSX.Element => {
    return (
      <>
        <h3>Returns</h3>
        <hr />
        <>
          <h5>Search By </h5>
          <InputGroup size="lg" className="mb-3">
            <InputGroup.Text id="basic-addon1">Receipt Id</InputGroup.Text>
            <Form.Control
              placeholder="Receipt id"
              aria-describedby="basic-addon1"
              onChange={(e) => filterByReceiptId(e.target.value)}
            />
          </InputGroup>
          <InputGroup size="lg" className="mb-3">
            <InputGroup.Text id="basic-addon1">IMEI</InputGroup.Text>
            <Form.Control
              placeholder="IMEI"
              aria-describedby="basic-addon1"
              onChange={(e) => filterByIMEI(e.target.value)}
            />
          </InputGroup>
        </>
        <div>
          <div>{receipts()}</div>
        </div>
      </>
    );
  };

  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  function endOfPrint() {
    setPrintView(false);
  }
  function findReceipt(id: number) {
    return receiptData.find((element) => {
      return element.receiptId === id;
    });
  }

  const receiptPrinter = (returnInfo: IReturnInfo): JSX.Element => {
    //get product
    //receipt receipt

    //generate the receipt

    const defaultReceipt: IReceipt = {
      receiptId: 0,
      branchId: 0,
      userId: 0,
      name: "",
      telephone: "",
      address: "",
      isDeleted: false,
      createdAt: new Date(),
      createdBy: "",
      items: "",
      receiptItems: [],
      isPrinted: false,
      total: 0,
      email: "",
      notes: "",
    };

    let receipt = findReceipt(returnInfo.receiptId);

    if (receipt === undefined) {
      receipt = defaultReceipt;
    }

    return (
      <>
        <div style={{ paddingTop: 20 }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary btn-lg">Print Return</Button>
            )}
            content={() => componentRef.current}
            //onBeforeGetContent={() => completeAction()}
            //onAfterPrint={() => setPrintView(false)}
          />
          {"  "}
          <Button variant="secondary btn-lg" onClick={endOfPrint}>
            Close
          </Button>
        </div>
        <div ref={componentRef}>
          <>
            <div
              //className="hidePrinterDivView"
              style={{
                width: "900px",
                margin: "auto",
                paddingTop: "75px",
              }}
            >
              <div className="center-info">
                <div style={{ fontSize: "50px" }}>
                  {context.Configuration.getSettings().name}
                </div>
              </div>
              <div className="center-info">
                <h5>{context.Configuration.getSettings().address}</h5>
              </div>

              <div className="center-info" style={{ paddingTop: 50 }}>
                <h3>Return Receipt</h3>
              </div>

              <div style={{ paddingTop: 50 }}> </div>
              <div className="row" style={{ paddingTop: 50 }}>
                <div className="col">
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Customer Info
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <div>{receipt.name}</div>
                    <div>{receipt.address}</div>
                    <div>{receipt.email}</div>
                    <div>{receipt.createdBy}</div>
                    <div>{receipt.createdAt.toString()}</div>
                  </div>
                </div>
                <div className="col" style={{ textAlign: "right" }}>
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Receipt
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <div>
                      <div># {returnInfo.receiptId}</div>
                    </div>
                    <div>{new Date(returnInfo.barcode).toDateString()}</div>
                    <div>{returnInfo.barcode}</div>
                  </div>
                </div>
              </div>

              <br />
              <Table
                striped
                bordered
                hover
                style={{ width: "100%" }}
                className="table-responsive-xl"
              >
                <thead>
                  <tr>
                    <th>Receipt</th>
                    <th>Description</th>
                    <th>Barcode/IMEI</th>
                    <th>Return Notes</th>
                    <th>Return By</th>
                    <th>Return Date</th>
                    <th>Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{returnInfo.receiptId}</td>
                    <td>{returnInfo.description}</td>
                    <td>{returnInfo.barcode}</td>
                    <td>{returnInfo.returnNotes}</td>
                    <td>{returnInfo.returnBy}</td>
                    <td>{returnInfo.returnDate.toString()}</td>
                    <td>
                      {returnInfo.isMobile.toString() === "true" ? "Yes" : "No"}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div
                className="col"
                style={{ textAlign: "right", paddingRight: 50 }}
              ></div>

              <div className="center-info" style={{ paddingTop: 60 }}>
                <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                  This is a return item receipt
                </div>
              </div>
            </div>
          </>
        </div>

        <div style={{ paddingTop: 20 }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary btn-lg">Print Retrurn</Button>
            )}
            content={() => componentRef.current}
            //onBeforeGetContent={() => completeAction()}
            //onAfterPrint={() => setPrintView(false)}
          />
          {"  "}
          <Button variant="secondary btn-lg" onClick={endOfPrint}>
            Close
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        loadingData()
      ) : (
        <>{printView ? receiptPrinter(returnInfo) : <>{returns()}</>}</>
      )}
    </>
  );
};
