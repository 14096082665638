import React from "react";
import { Configuration } from "./Configuration/Configuration";
import { IApplicationContext } from "./IApplicationContext";
import { View } from "./view/View";
import { AuthenticatedUser } from "./AuthenticatedUser/AuthenticatedUser";
import { ApplicationError } from "./ApplicationError/ApplicationError";
import { Data } from "./data/Data";
import { Receipt } from "./receipt/Receipt";

export const defaults: IApplicationContext = {
  Configuration: new Configuration(),
  ViewPage: new View(),
  AuthenticatedUser: new AuthenticatedUser(),
  ApplicationError: new ApplicationError(),
  Data: new Data(),
  Receipt: new Receipt(),
};

export default React.createContext<IApplicationContext>(defaults);
