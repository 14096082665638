import { IProduct } from "../../types/IProduct";
import { IReceipt } from "../../types/IReceipt";
import { IData } from "./IData";

let products: IProduct[] = [];
let receipts: IReceipt[] = [];
let receipt: IReceipt;

export class Data implements IData {
  public getProducts(): IProduct[] {
    return products;
  }
  public setProducts(p: IProduct[]) {
    products = p;
  }
  public setReceipts(r: IReceipt[]) {
    receipts = r;
  }
  public getReceipts() {
    return receipts;
  }
  public getSelectedReceipt(): IReceipt {
    return receipt;
  }
  public setSelectedReceipt(r: IReceipt) {
    receipt = r;
  }
}
