import { ISettings } from "../../types/ISettings";
import { IConfiguration } from "./IConfiguration";

let menuId: number;
let settings: ISettings;

export class Configuration implements IConfiguration {
  public setMenuId(id: number): void {
    menuId = id;
  }
  public getMenuId(): number {
    return menuId;
  }
  public setSettings(s: ISettings): void {
    settings = s;
  }
  public getSettings(): ISettings {
    return settings;
  }
}
